<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="7"
      >
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <base-material-card
              color="#00867c"
              icon="mdi-flask"
            >
              <template v-slot:after-heading>
                <div class="display-2 font-weight-light my-2">
                  {{ product.productName }}
                </div>
              </template>
              <v-row>
                <v-col cols="6">
                  <v-card-text>
                    <v-icon class="mr-1">
                      mdi-android-messages
                    </v-icon>
                    {{ product.description }}
                  </v-card-text>
                </v-col>
                <v-col cols="3">
                  <v-card-text>
                    <v-icon class="mr-1">
                      mdi-cube
                    </v-icon>
                    {{ product.gmnNumber }}
                  </v-card-text>
                </v-col>
                <v-col cols="3">
                  <v-card-text>
                    <v-icon class="mr-1">
                      mdi-ruler
                    </v-icon>
                    {{ unit.name }} ({{ unit.abbreviation }})
                  </v-card-text>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col cols="2">
                  <v-btn
                    outlined
                    color="primary"
                    to="/settings/products"
                  >
                    Back to products
                  </v-btn>
                </v-col>
                <v-col cols="2" />
                <v-col cols="2">
                  <v-btn
                    block
                    color="primary"
                  >
                    <v-icon>
                      mdi-clipboard-text-multiple
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    block
                    color="secondary"
                  >
                    <v-icon>
                      mdi-link-variant
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    block
                    color="success"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    block
                    color="error"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <productListings :product="productId" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="5"
      >
        <productLinks :product="productId" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import productAPI from '@/services/productServices'
  import masterDataAPI from '@/services/masterData'
  import productLinks from '@/views/components/products/ProductLinks'
  import productListings from '@/views/components/products/ProductListings'

  export default {
    Name: 'ProductDetail',
    components: {
      productLinks,
      productListings,
    },
    props: {
      productId: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return {
        product: {
          productName: '',
          description: '',
          unitofMeasure: 1,
        },
        unit: {
          abbreviation: '',
          name: '',
          unitId: 0,
        },
      }
    },
    created: function () {
      productAPI.getProductById(this.productId)
        .then(response => {
          this.product = response.data
          masterDataAPI.getUnitById(this.product.unitofMeasure)
            .then(response => {
              this.unit = response.data
            })
        })
    },
  }
</script>
